// lib/withApollo.js
import withApollo from 'next-with-apollo';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink
} from '@apollo/client';
import Cookies from 'universal-cookie';
import { SESSION_TOKEN_KEY } from '@hooks/useAuth';
import { GQL_ENDPOINT } from '@lib/config';

export default withApollo(
  // @ts-ignore
  ({ initialState, ctx, headers }) => {
    const cookies = new Cookies(!process.browser ? headers?.cookie : null);
    const cookieToken = cookies.get(SESSION_TOKEN_KEY);
    const headerToken = headers?.cookie
      ? headers?.cookie[SESSION_TOKEN_KEY]
      : null;
    const token = cookieToken || headerToken;
    const auth = token
      ? {
          Authorization: `Bearer ${token}`
        }
      : {};

    const httpLink = new HttpLink({
      uri: GQL_ENDPOINT,
      headers: {
        'content-type': 'application/json',
        ...auth
      }
    });
    // @ts-ignore
    return new ApolloClient({
      link: httpLink,
      cache: new InMemoryCache().restore(initialState || {})
    });
  },
  {
    render: ({ Page, props }) => {
      return (
        <ApolloProvider client={props.apollo}>
          <Page {...props} />
        </ApolloProvider>
      );
    }
  }
);
